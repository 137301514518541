import { StockItemDetails } from '../../common/types/StockItemTypes';
import { ContentClass } from '../Shared/enums';

export interface ContentCollectionState {
  collection?: ContentCollection;
  stockItemDetails?: CollectionStockItemDetails;
  selectedTab: CrossAssetTab;
  collectionDirectoryLink: string;
}

export interface CollectionStockItemDetails {
  audio: StockItemDetails[];
  image: StockItemDetails[];
  video: StockItemDetails[];
}

export interface ContentCollection {
  name: string;
  content: {
    audio: number[];
    image: number[];
    video: number[];
  };
}

export type NavContentCollection = {
  active?: boolean;
  dateAdded?: string;
  dateUpdated?: string;
  description?: string;
  id?: number;
  name?: string;
  searchIndexable?: boolean;
  showInApi?: boolean;
  contentClasses?: ContentClass[];
  view: NavContentCollectionView;
};

type NavContentCollectionView = {
  routeUri?: string;
  redirectUri?: string;
  heroBgImgUrl?: string;
  heroTitle?: string;
  heroSubtitle?: string;
  description?: string;
  metaTitle?: string;
  metaDescription?: string;
  metaKeywords?: string;
  leftXsellTitle?: string;
  leftXsellLinkTitle?: string;
  leftXsellLinkUrl?: string;
  leftXsellBgImgUrl?: string;
  rightXsellTitle?: string;
  rightXsellLinkTitle?: string;
  rightXsellLinkUrl?: string;
  rightXsellBgImgUrl?: string;
  allowSeoIndex?: boolean;
  showInDirectory?: boolean;
  showInMainNavDropdown?: boolean;
  directoryBackgroundImageUrl?: string;
};

export enum CrossAssetTab {
  video = 'video',
  audio = 'audio',
}
