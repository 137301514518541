import { Category } from '../SearchTypes';
import { ALL_VIDEOS_CONTENT_TYPE } from './SearchFilterOptions';

export const contentTypes = [
  {
    label: 'All',
    value: 'all-videos',
  },
  {
    label: 'Footage',
    value: 'footage',
  },
  {
    label: 'Backgrounds',
    value: 'motion-backgrounds',
  },
  {
    label: 'Templates',
    value: 'templates',
  },
];

export const qualities = [
  {
    label: 'HD',
    value: 'HD',
  },
  {
    label: '4K',
    value: '4K',
  },
];

export const frameRates = [
  {
    label: '23.98 / 24',
    value: 'fps24',
    name: 'fps24',
  },
  {
    label: '25',
    value: 'fps25',
    name: 'fps25',
  },
  {
    label: '29.97 / 30',
    value: 'fps30',
    name: 'fps30',
  },
  {
    label: '50',
    value: 'fps50',
    name: 'fps50',
  },
  {
    label: '59.94 / 60',
    value: 'fps60',
    name: 'fps60',
  },
];

export const mediaDetails = [
  {
    label: 'Model Released',
    value: 'talentReleased',
    name: 'talentReleased',
  },
  {
    label: 'Property Released',
    value: 'propertyReleased',
    name: 'propertyReleased',
  },
];

export const templateTypes = [
  {
    label: 'All Templates',
    value: '',
    categoryId: null,
    contentType: 'templates',
  },
  {
    label: 'After Effects',
    value: '89653',
    categoryId: 89653,
    contentType: 'templates',
    urlId: 'after-effects-templates',
  },
  {
    label: 'Premiere Pro',
    value: '51',
    categoryId: 51,
    contentType: 'templates',
    urlId: 'premiere-pro-templates',
  },
  {
    label: 'DaVinci Resolve',
    value: '89673',
    categoryId: 89673,
    contentType: 'templates',
    urlId: 'davinci-resolve-templates',
  },
  {
    label: 'Apple Motion',
    value: '19',
    categoryId: 19,
    contentType: 'templates',
    urlId: 'apple-motion-templates',
  },
];

export const categoryTypes = [
  {
    label: 'All Categories',
    value: '',
    categoryId: null,
    contentType: 'footage',
  },
  {
    label: 'Aerial',
    value: '1',
    categoryId: 1,
    contentType: 'footage',
    urlId: 'aerial',
  },
  {
    label: 'Animals',
    value: '2',
    categoryId: 2,
    contentType: 'footage',
    urlId: 'animals',
  },
  {
    label: 'Business and Professions',
    value: '4',
    categoryId: 4,
    contentType: 'footage',
    urlId: 'business-and-professions',
  },
  {
    label: 'Food and Beverage',
    value: '5',
    categoryId: 5,
    contentType: 'footage',
    urlId: 'food-and-beverage',
  },
  {
    label: 'Green Screen',
    value: '24',
    categoryId: 24,
    contentType: 'footage',
    urlId: 'green-screen',
  },
  {
    label: 'Historical and Archival',
    value: '6',
    categoryId: 6,
    contentType: 'footage',
    urlId: 'historical-and-archival',
  },
  {
    label: 'Holidays and Celebrations',
    value: '7',
    categoryId: 7,
    contentType: 'footage',
    urlId: 'holidays-and-celebrations',
  },
  {
    label: 'Nature',
    value: '9',
    categoryId: 9,
    contentType: 'footage',
    urlId: 'nature',
  },
  {
    label: 'People',
    value: '10',
    categoryId: 10,
    contentType: 'footage',
    urlId: 'people',
  },
  {
    label: 'Slow Motion',
    value: '11',
    categoryId: 11,
    contentType: 'footage',
    urlId: 'slow-motion',
  },
  {
    label: 'Sports and Recreation',
    value: '12',
    categoryId: 12,
    contentType: 'footage',
    urlId: 'sports-and-recreation',
  },
  {
    label: 'Technology',
    value: '13',
    categoryId: 13,
    contentType: 'footage',
    urlId: 'technology',
  },
  {
    label: 'Time Lapse',
    value: '14',
    categoryId: 14,
    contentType: 'footage',
    urlId: 'time-lapse',
  },
  {
    label: 'Transportation',
    value: '15',
    categoryId: 15,
    contentType: 'footage',
    urlId: 'transportation',
  },
  {
    label: 'Effects and Lower Thirds',
    value: '20',
    categoryId: 20,
    contentType: 'footage',
    urlId: 'effects-and-lower-thirds',
  },
  {
    label: 'Places and Landmarks',
    value: '22',
    categoryId: 22,
    contentType: 'footage',
    urlId: 'places-and-landmarks',
  },
  {
    label: '360° and VR',
    value: '23',
    categoryId: 23,
    contentType: 'footage',
    urlId: '360-files',
  },
  {
    label: 'Lifestyle',
    value: '89666',
    categoryId: 89666,
    contentType: 'footage',
    urlId: 'lifestyle',
  },
  {
    label: 'Medical',
    value: '89665',
    categoryId: 89665,
    contentType: 'footage',
    urlId: 'medical',
  },
  {
    label: 'All Categories',
    value: '',
    categoryId: null,
    contentType: 'templates',
  },
  {
    label: 'Intro',
    value: '25',
    categoryId: 25,
    contentType: 'templates',
    urlId: 'intro',
  },
  {
    label: 'Business',
    value: '26',
    categoryId: 26,
    contentType: 'templates',
    urlId: 'business',
  },
  {
    label: 'Logo Reveal',
    value: '27',
    categoryId: 27,
    contentType: 'templates',
    urlId: 'logo-reveal',
  },
  {
    label: 'Typography',
    value: '28',
    categoryId: 28,
    contentType: 'templates',
    urlId: 'typography',
  },
  {
    label: 'Presentation',
    value: '29',
    categoryId: 29,
    contentType: 'templates',
    urlId: 'presentation',
  },
  {
    label: 'Slideshow',
    value: '30',
    categoryId: 30,
    contentType: 'templates',
    urlId: 'slideshow',
  },
  {
    label: 'Cinematic',
    value: '31',
    categoryId: 31,
    contentType: 'templates',
    urlId: 'cinematic',
  },
  {
    label: 'Titles',
    value: '32',
    categoryId: 32,
    contentType: 'templates',
    urlId: 'titles',
  },
  {
    label: 'Photo',
    value: '33',
    categoryId: 33,
    contentType: 'templates',
    urlId: 'photo',
  },
  {
    label: 'Promo',
    value: '34',
    categoryId: 34,
    contentType: 'templates',
    urlId: 'promo',
  },
  {
    label: 'All Categories',
    value: '',
    categoryId: null,
    contentType: 'motion-backgrounds',
  },
  {
    label: 'Abstract',
    value: '35',
    categoryId: 35,
    contentType: 'motion-backgrounds',
    urlId: 'abstract',
  },
  {
    label: 'Space',
    value: '37',
    categoryId: 37,
    contentType: 'motion-backgrounds',
    urlId: 'space',
  },
  {
    label: 'Love',
    value: '43',
    categoryId: 43,
    contentType: 'motion-backgrounds',
    urlId: 'love',
  },
  {
    label: 'Medical',
    value: '45',
    categoryId: 45,
    contentType: 'motion-backgrounds',
    urlId: 'medical',
  },
  {
    label: 'Music',
    value: '47',
    categoryId: 47,
    contentType: 'motion-backgrounds',
    urlId: 'music',
  },
  {
    label: 'Technology',
    value: '48',
    categoryId: 48,
    contentType: 'motion-backgrounds',
    urlId: 'technology',
  },
  {
    label: 'Religion',
    value: '49',
    categoryId: 49,
    contentType: 'motion-backgrounds',
    urlId: 'religion',
  },
  {
    label: 'Business',
    value: '89667',
    categoryId: 89667,
    contentType: 'motion-backgrounds',
    urlId: 'business',
  },
  {
    label: 'Colors',
    value: '89668',
    categoryId: 89668,
    contentType: 'motion-backgrounds',
    urlId: 'colors',
  },
  {
    label: 'Nature',
    value: '89670',
    categoryId: 89670,
    contentType: 'motion-backgrounds',
    urlId: 'nature',
  },
  {
    label: 'News',
    value: '89671',
    categoryId: 89671,
    contentType: 'motion-backgrounds',
    urlId: 'news',
  },
  {
    label: 'Celebrations',
    value: '89672',
    categoryId: 89672,
    contentType: 'motion-backgrounds',
    urlId: 'celebrations',
  },
  {
    label: 'Effects and Overlays',
    value: '89669',
    categoryId: 89669,
    contentType: 'motion-backgrounds',
    urlId: 'effects-and-overlays',
  },
  {
    label: 'Particles',
    value: '41',
    categoryId: 41,
    contentType: 'motion-backgrounds',
    urlId: 'particles',
  },
  {
    label: 'Shapes',
    value: '50',
    categoryId: 50,
    contentType: 'motion-backgrounds',
    urlId: 'shapes',
  },
  {
    label: 'Water',
    value: '42',
    categoryId: 42,
    contentType: 'motion-backgrounds',
    urlId: 'water',
  },
  {
    label: 'Countdown',
    value: '44',
    categoryId: 44,
    contentType: 'motion-backgrounds',
    urlId: 'countdown',
  },
  {
    label: 'Energy',
    value: '40',
    categoryId: 40,
    contentType: 'motion-backgrounds',
    urlId: 'energy',
  },
  {
    label: 'Fire',
    value: '38',
    categoryId: 38,
    contentType: 'motion-backgrounds',
    urlId: 'fire',
  },
  {
    label: 'Flowers',
    value: '39',
    categoryId: 39,
    contentType: 'motion-backgrounds',
    urlId: 'flowers',
  },
  {
    label: 'Science',
    value: '46',
    categoryId: 46,
    contentType: 'motion-backgrounds',
    urlId: 'science',
  },
];

export function keepOnlyCategoryIds(categoryAndTemplateIds: string): string {
  if (!categoryAndTemplateIds) {
    return categoryAndTemplateIds;
  }

  return categoryAndTemplateIds
    .split(',')
    .filter((id) => categoryTypes.find(({ value }) => value === id))
    .join(',');
}

export function keepOnlyTemplateTypeIds(
  categoryAndTemplateIds: string
): string {
  if (!categoryAndTemplateIds) {
    return categoryAndTemplateIds;
  }

  return categoryAndTemplateIds
    .split(',')
    .filter((id) => templateTypes.find(({ value }) => value === id))
    .join(',');
}

export function getCategoriesForContentType(contentType): Category[] {
  if (contentType === ALL_VIDEOS_CONTENT_TYPE) {
    return [];
  }

  const sortedCategories = categoryTypes.sort(function (a, b) {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  });

  const filteredCategories = sortedCategories.filter((category: Category) => {
    return category.contentType === contentType;
  });

  const categoriesIndex = filteredCategories.findIndex(
    (obj) => obj.label === 'All Categories'
  );
  if (categoriesIndex > -1) {
    const allCategoriesObj = filteredCategories.splice(categoriesIndex, 1)[0];
    filteredCategories.unshift(allCategoriesObj);
  }
  return filteredCategories;
}

export const premiereProTemplateTypeId = '51';
export const appleMotionTemplateTypeId = '19';
export const daVinciResolveTemplateTypeId = '89673';
export const templatesContentTypeLabel = 'templates';
